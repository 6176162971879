<template>
  <div class="grid admintext-demo">
    <Toolbar class="toolbar_top">
      <template v-slot:start>
        <button class="p-link" id="btn_back" @click="backToRapport">
          <i class="pi pi-angle-left"></i>
        </button>
        <div class="my-2">
          <h3>{{ t('statisticsEvaluations') }}</h3>
        </div>
      </template>
    </Toolbar>
    <div class="p-datatable-loading-overlay p-component-overlay" v-if="loadingdomains" style="

          display: -webkit-box;
          display: -ms-flexbox;
          display: flex;
          -webkit-box-align: center;
          -ms-flex-align: center;
          align-items: center;
          -webkit-box-pack: center;
          -ms-flex-pack: center;
          justify-content: center;
          z-index: 2;">
      <i class="p-datatable-loading-icon pi-spin pi pi-spinner load_chart"></i>
    </div>
    <div class="col-12 lg:col-12">

      <div class="card section_board doughnut">
        <div class="bg_title" style="display: flex; justify-content: end;">
          <Button icon="pi pi-download" accept="image/*"
                  class="p-button-text p-button-lg"
                  :maxFileSize="1000000"
                  aria-label="Import" chooseLabel="Import" @click="exportDomainEvaluations()"/>
        </div>

        <Chart type="doughnut" ref="chartDomainEvaluations" :data="pieDataGlobal" :options="pieOptions"
               :plugins="[chartJsPlugin]" class="chart_domaine"></Chart>
      </div>
    </div>

    <div class="col-12">
      <div class="card">
        <DataTable :value="listDomains" :totalRecords="totalItemsDomains"
                   dataKey="index" :paginator="true" :rows="nbItems"
                   :rowsPerPageOptions="[5,10,30,50]"
                   :rowHover="true"
                   class="p-datatable-gridlines table-filter-texte"
                   v-model:filters="filters1" filterDisplay="menu" :loading="loadingdomains" :filters="filters1"
                   responsiveLayout="scroll"
                   :globalFilterFields="['domain_name', 'sub_domain_name_d', 'progression', 'conformity']"
                   @filter="filterDataTableEvent($event)"
        >
          <template #empty>
            {{ t('NoDataToDisplay') }}
          </template>
          <template #loading>
            {{ t('LoadingDataPleaseWait') }}
          </template>
          <template #header>
            <div class="table-header-container">

              <Button label="Export" icon="pi pi-download" class="add-text  mr-2"
                      @click="exportDomain"/>

            </div>
          </template>
          <Column field="domain_name" header="Domaine" style="min-width:7rem"
                  :showFilterMatchModes="false">
            <template #body="{data}">
              {{ data.domain_name }}
            </template>
            <template #filter="{filterModel}">
              <Dropdown v-model="filterModel.value" :options="listDomainsConst" placeholder=" Any "
                        class="p-column-filter" :showClear="true">
                <template #value="slotProps">
                    <span :class="'customer-badge status-' + slotProps.value"
                          v-if="slotProps.value">{{ slotProps.value }}</span>
                  <span v-else>{{ t('select') }}</span>
                </template>
                <template #option="slotProps">
                        <span :class="'customer-badge status-' + slotProps.option">{{
                            slotProps.option
                          }}</span>
                </template>
              </Dropdown>
            </template>
          </Column>

          <Column field="sub_domain_name_d" header="Sous-domaine" style="min-width:7rem"
                  :showFilterMatchModes="false">
            <template #body="{data}">
              {{ data.sub_domain_name }}
            </template>
            <template #filter="{filterModel}">
              <Dropdown v-model="filterModel.value" :options="listSubDomainsConst" placeholder=" Any "
                        class="p-column-filter" :showClear="true">
                <template #value="slotProps">
                    <span :class="'customer-badge status-' + slotProps.value"
                          v-if="slotProps.value">{{ slotProps.value }}</span>
                  <span v-else>{{ t('select') }}</span>
                </template>
                <template #option="slotProps">
                        <span :class="'customer-badge status-' + slotProps.option">{{
                            slotProps.option
                          }}</span>
                </template>
              </Dropdown>
            </template>
          </Column>

          <Column :header="t('progressRate')" filterField="progression" dataType="numeric"
                  style="min-width:5rem"
                  class="progressBarValue" :showFilterMatchModes="false">

            <template #body="slotProps">
              <AxProgressBar :value="Number(slotProps.data.progression).toFixed(1)"
                             v-if="slotProps.data.progression<=50  && !slotProps.data.verif_pi_na"
                             :showValue="true" style="height:20px" class="valuered"></AxProgressBar>

              <AxProgressBar :value="Number(slotProps.data.progression).toFixed(1)"
                             v-if="slotProps.data.progression>50  && slotProps.data.progression<70 && !slotProps.data.verif_pi_na"
                             :showValue="true"
                             style="height:20px" class="valueyellow"></AxProgressBar>

              <AxProgressBar :value="Number(slotProps.data.progression).toFixed(1)"
                             v-if="slotProps.data.progression>=70 && !slotProps.data.verif_pi_na"
                             :showValue="true" style="height:20px" class="valuegreen"></AxProgressBar>
            </template>

            <template #filter={filterModel}>
              <Slider v-model="filterModel.value" range class="m-3"></Slider>
              <div class="flex align-items-center justify-content-between px-2">
                <span>{{ filterModel.value ? filterModel.value[0] : 0 }}</span>
                <span>{{ filterModel.value ? filterModel.value[1] : 100 }}</span>
              </div>
            </template>
          </Column>

          <Column :header="t('complianceRate')" filterField="conformity" dataType="numeric"
                  style="min-width:5rem"
                  class="progressBarValue" :showFilterMatchModes="false">

            <template #body="slotProps">
              <AxProgressBar :value="Number(slotProps.data.conformity).toFixed(1)"
                             v-if="slotProps.data.conformity<=50 && !slotProps.data.verif_pi_na"
                             :showValue="true" style="height:20px" class="valuered"></AxProgressBar>
              <AxProgressBar :value="Number(slotProps.data.conformity).toFixed(1)"
                             v-if="slotProps.data.conformity>50 && slotProps.data.conformity<70  && !slotProps.data.verif_pi_na"
                             :showValue="true"
                             style="height:20px" class="valueyellow"></AxProgressBar>
              <AxProgressBar :value="Number(slotProps.data.conformity).toFixed(1)"
                             v-if="slotProps.data.conformity>=70  && !slotProps.data.verif_pi_na"
                             :showValue="true" style="height:20px" class="valuegreen"></AxProgressBar>
            </template>

            <template #filter={filterModel}>
              <Slider v-model="filterModel.value" range class="m-3"></Slider>
              <div class="flex align-items-center justify-content-between px-2">
                <span>{{ filterModel.value ? filterModel.value[0] : 0 }}</span>
                <span>{{ filterModel.value ? filterModel.value[1] : 100 }}</span>
              </div>
            </template>
          </Column>

          <Column v-for="(status, index) in listEvaluationStatus.slice(1)"
                  :key="index"
                  :field="status.name"
                  :header="status.name"
                  style="min-width:7rem"
                  :showFilterMatchModes="false">
            <template #body="{data}">
              <span v-if="data.status_summary[status.name]">
                           {{ data.status_summary[status.name] }}
              </span>
              <span v-else>
                          0
              </span>
            </template>

          </Column>

        </DataTable>
      </div>
    </div>
  </div>
  <Toast/>
</template>

<script>


import {usePrimeVue} from "primevue/config";
import {useStore} from "vuex";
import {Actions} from "@/store/enums/StoreEnums";
import {computed} from "vue";
import {saveAs} from 'file-saver';
import {FilterMatchMode} from "primevue/api";
import AxProgressBar from "@/components/AxProgressBar.vue";
import {useToast} from "primevue/usetoast";

export default {
  name: "Evaluation",
  components: {AxProgressBar},
  data() {
    return {
      listSites: [],
      listDomains: [],
      totalItemsDomains: 0,
      nbItems: 10,
      loadingdomains: true,
      filters1: null,
      listEvaluationStatus: [],
      chartJsPlugin: {
        beforeDraw: (chartCtx) => {
          const ctx = chartCtx.canvas.getContext('2d');
          ctx.save();
          ctx.globalCompositeOperation = 'destination-over';
          ctx.fillStyle = 'white';
          ctx.fillRect(0, 0, chartCtx.width, chartCtx.height);
          ctx.restore();
        },
      },
      pieDataGlobal: {
        labels: [],
        datasets: [{
          data: [],
          backgroundColor: []
        }]
      },
      pieOptions: {
        cutoutPercentage: 50,
        plugins: {
          tooltip: {
            enabled: true,
          },
          legend: {
            display: true,
            labels: {
              color: '#A0A7B5',
              padding: 20,
              font: {
                size: 14,
              },
            },
            position: 'bottom',
            fullSize: true,
            boxWidth: 10,
          },
          layout: {
            padding: {
              left: 10,
              right: 10,
              top: 10,
              bottom: 10
            }
          },
          datalabels: {
            color: 'white',
            font: {
              weight: '300',
              size: 12,
            },
            formatter: (value, context) => {
              const datasetLabel = Math.round(context.dataset.data[context.dataIndex]);
              return datasetLabel;
            }
          },
          title: {
            display: true,
            text: 'Nombre d\'exigences par domaine réglementaire'
          },
        },
        responsive: true,
        maintainAspectRatio: true,
        aspectRatio: 2,
        hoverMode: 'index',
        stacked: false,
      },
      domain_name_filter: null,
      sub_domain_name_filter: null,
      progression_filter: null,
      conformity_filter: null,
      loadingchart: false,
      listDomainsConst: [],
      listSubDomainsConst: [],

    }
  },
  setup() {
    const primeVue = usePrimeVue();
    const store = useStore();
    const list_site = computed(() => {
      return store.getters.getListSites
    })
    const id_site = computed(() => {
      return store.getters.currentSite.id
    });
    const user = computed(() => {
      return store.getters.currentUser
    });
    const toast = useToast();

    return {t: primeVue.config.StoreTranslation.t, store, list_site, id_site, user, toast}
  },
  watch: {
    async list_site(value) {
      if (value) {
        this.listSites = value
        await this.getStaticsEvaluations()
        await this.getDomainsWithStatus()
      }
    },
  },
  async mounted() {
    if (this.id_site) {
      this.listSites = this.list_site
      await this.getAllEvaluationStatus()
      if (this.listSites) {
        await this.getStaticsEvaluations()
        await this.getDomainsWithStatus()
      }

    }
  },
  async created() {
    this.initFilters1();
  },
  methods: {
    backToRapport() {
      this.$router.push('/rapport');
    },

    getStaticsEvaluations() {
      this.loadingchart = !!this.id_site
      let dataElem = []
      let dataLabelGlobal = []
      let dataColorLabel = []
      this.pieDataGlobal.datasets[0].data = []
      this.pieDataGlobal.labels = []
      const saturation = 70;
      const lightness = 60;

      let sites = this.listSites?.length > 0 ? this.listSites : [this.id_site]
      return this.store.dispatch(Actions.GET_STATICS_EVALUATIONS, {
        sites: sites
      }).then((data) => {
        data.forEach((element, index) => {
          dataLabelGlobal.push(element.theme_name + ' - ' + element.domain_name)
          dataElem.push(element.nb_evaluation)
          const hue = (index * 360 / data.length) % 360;
          const color = `hsl(${hue}, ${saturation}%, ${lightness}%)`;
          dataColorLabel.push(color)
        })
        if (data.length < 25) {
          this.pieOptions.aspectRatio = 2
        } else if (data.length < 35) {
          this.pieOptions.aspectRatio = 1.4
        } else {
          this.pieOptions.aspectRatio = 1
        }

        this.pieDataGlobal.labels = dataLabelGlobal
        this.pieDataGlobal.datasets[0].data = dataElem
        this.pieDataGlobal.datasets[0].backgroundColor = dataColorLabel

        this.loadingchart = false
      })
    },
    exportDomainEvaluations() {
      saveAs(this.$refs.chartDomainEvaluations.getChart().toBase64Image('image/jpeg', 1), 'statistiques-evaluations.jpeg');
    },

    getDomainsWithStatus() {
      let sites = this.listSites?.length > 0 ? this.listSites : [this.id_site]
      this.loadingdomains = true
      this.listDomainsConst = [];
      this.listSubDomainsConst = [];
      return this.store.dispatch(Actions.GET_DOMAINS_WITH_STATUS, {
        sites: sites
      }).then((data) => {
        data[0].forEach(element => {
          element['status_summary'] = element['status_summary'].split(',')
          element['status_summary'] = element['status_summary'].reduce((acc, pair) => {
            const [key, value] = pair.split(':');
            acc[key.trim()] = parseInt(value);
            return acc;
          }, {})
          const elementFilter = data[1].filter(item =>
              item.domain_id === element.domain_id && item.sub_domain_id === element.sub_domain_id
          );
          if (elementFilter) {
            element['verif_pi_na'] = this.verifPiNa(elementFilter[0]['compliance_na_count'], elementFilter[0]['compliance_pi_count'], elementFilter[0]['compliances_count'])

            if (element['verif_pi_na']) {
              element['progression'] = -1
              element['conformity'] = -1
            } else {
              element['progression'] = elementFilter[0]['progression']
              element['conformity'] = elementFilter[0]['conformity']
            }

          }
          const domainExits = this.listDomainsConst.filter(item =>
              item === element.domain_name
          );
          if (domainExits.length == 0)
            this.listDomainsConst.push(element.domain_name)

          let sub_domain_name_d = element.sub_domain_name + ' ( ' + element.domain_name + ' )'
          this.listSubDomainsConst.push(sub_domain_name_d)

          element['sub_domain_name_d'] = sub_domain_name_d
        })
        this.listDomainsConst.sort((a, b) => a.localeCompare(b));
        this.listSubDomainsConst.sort((a, b) => a.localeCompare(b));

        this.listDomains = data[0]
        this.totalItemsDomains = data[0].length
        this.loadingdomains = false

      })
    },
    filterDataTableEvent(event) {
      this.domain_name_filter = event.filters.domain_name.value
      if (event.filters.sub_domain_name_d.value) {
        this.sub_domain_name_filter = event.filters.sub_domain_name_d.value.replace(/\s*\([^)]*\)$/, '')
      }

      this.progression_filter = event.filters.progression.value
      this.conformity_filter = event.filters.conformity.value
    },
    initFilters1() {
      this.filters1 = {
        'domain_name': {value: null, matchMode: FilterMatchMode.CONTAINS},
        'sub_domain_name_d': {value: null, matchMode: FilterMatchMode.CONTAINS},
        'progression': {value: null, matchMode: FilterMatchMode.BETWEEN},
        'conformity': {value: null, matchMode: FilterMatchMode.BETWEEN},
      }
    },
    clearFilter1() {
      this.initFilters1();
    },

    getAllEvaluationStatus() {
      this.store.dispatch(Actions.GET_ALL_EVALUATION_STATUS, {
        customHeader: false
      }).then(data => {
        this.listEvaluationStatus = data


      })
    },
    exportDomain() {
      let user = '/api/users/' + this.user.id
      let sites = this.listSites?.length > 0 ? this.listSites : [this.id_site]


      this.store.dispatch(Actions.EXPORTS_POST, {
        user: user,
        model: "statisticEvaluationReportExport",
        params: {
          'sites': sites,
          'userId': this.user.id,
          'domain_name': this.domain_name_filter,
          'sub_domain_name': this.sub_domain_name_filter,
          'progression': this.progression_filter,
          'conformity': this.conformity_filter,
          site_id: parseInt(this.id_site),
        },
      }).then(() => {
        this.btnExport = false
        this.toast.add({
          severity: 'success',
          detail: this.t('exportWasSuccessfullySent'),
          life: 8000
        });
      })
    },

    verifPiNa(NA, PI, nbCompliance) {
      return parseInt(NA) + parseInt(PI) === nbCompliance
    },
  }
}
</script>

<style scoped>
</style>